.table {
	margin-bottom: 0;
	thead {
		th {
			font-weight: 600;
			border-bottom-width: 4px;
			&.dividers {
				border-right: 1px solid $table-border-color;
				&:last-child {
					border-right: 0;
				}
			}
			&.sortable {
				cursor: pointer;
				-webkit-user-select: none; /* Safari 3.1+ */
				-moz-user-select: none; /* Firefox 2+ */
				-ms-user-select: none; /* IE 10+ */
				user-select: none; /* Standard syntax */
				&:hover {
					background-color: $table-hover-bg;
					&:active {
						background-color: $light;
					}
				}
			}
			.sorting-caret {
				vertical-align: text-top;
				font-size: 150%;
				line-height: 100%;
				margin-left: map-get($spacers, 1);
				margin-right: calc(-1 * calc(0.25rem + 24px));
			}
		}
	}
	tbody {
		tr.collapse-open {
			background-color: rgba($primary, .15);
		}
		td a {
			display: inline-block;
			min-height: 100%;
			width: 100%;
			color: inherit;
			text-decoration: none !important;
			&:hover {
				color: inherit;
			}
		}
	}
}
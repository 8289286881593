.btn {
	&.round {
		border-radius: 300px;
	}
	&.straight {
		border-radius: 0;
	}
	&.nofocus:focus {
		box-shadow: none;
	}
	&.nofocus:not(:disabled):not(.disabled):active:focus {
		box-shadow: none;
	}
}
.btn-rO-text {
	&.btn-rO-text:hover {
		color: unset;
		background-color: unset !important;
	}
	padding: 0;
	background-color: unset;
	border: none;
}
.btn-rO-circle {
	padding-top: 0;
	padding-bottom: 0;
	height: 42px;
	&.btn-sm {
		height: 32px;
	}
	&.btn-lg {
		height: 54px;
	}
}
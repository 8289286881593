/* Typography CSS */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
//	margin-bottom: 0 !important;
}

.bg-dark {
	.text-muted {
		color: $gray-400 !important;
	}
}

[class*="title-"] {
	font-weight: 700;
	&.title-1 {
		font-size: 150%;
		font-weight: 600;
	}
	&.title-2 {
		font-size: 140%;
		font-weight: 600;
	}
	&.title-3 {
		font-size: 120%;
	}
	&.title-4 {
		font-size: 100%;
	}
	&.title-5 {
		font-size: 92%;
	}
}

.sub-title {
	font-weight: normal;
	color: $gray-600;
	font-size: .9rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	
}

/*-------------------------------------------------------*/

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	
}

/* Extra large devices (large desktops) */
/* No media query since the extra-large breakpoint has no upper bound on its width */
/* Reference */
/*

	@mixin boxShadow($_HOffset, $_VOffset, $_blur, $_color, $_opacity, $_spread)			//	@include boxShadow(0, 0, 22px, #000, 0.75, 1px);	
	
*/


@mixin boxShadow($_HOffset, $_VOffset, $_blur, $_color, $_opacity, $_spread) {
	-webkit-box-shadow: $_HOffset $_VOffset $_blur $_spread rgba($_color, $_opacity);
	-moz-box-shadow: $_HOffset $_VOffset $_blur $_spread rgba($_color, $_opacity);
	box-shadow: $_HOffset $_VOffset $_blur $_spread rgba($_color, $_opacity);
}



/* Core Layout CSS */

._layout-page {
	position: relative;
	width: 100%;
	height: auto;
	min-height: 100%;
	overflow-x: hidden;
//	box-shadow: inset 0px 50px 2000px 0px #000;
}

._layout-header {
	z-index: 1040;
	width: 100%;
}

._layout-sidebar {
	position: absolute;
	z-index: 1000;
	-webkit-transition: left 0.2s; /* Safari */
	transition: left 0.2s;
}
._layout-sidebar.-fixed {
	position: fixed;
	overflow: hidden;
}
._layout-sidebar .-container {
	position: relative;
	height: 100%;
	-webkit-transition: left 0.2s; /* Safari */
	transition: left 0.2s;
	overflow: hidden;
}
._layout-sidebar .-wrapper {
	width: 250px;
	height: auto;
	overflow: hidden;
	float: left;
}

._layout-body {
	position: relative;
	z-index: 900;
	height: 100%;
	-webkit-transition: margin-left 0.2s; /* Safari */
	transition: margin-left 0.2s;
//	overflow: scroll;
//	background-color: $gray-300x;
}

._positioning-box {
}
/* Section CSS */

.section {
//	overflow: hidden;
	.react-parallax {
		.react-parallax-content {
			height: 100%;
		}
		&.d-flex {
			.react-parallax-content {
				width: 100%;
			}
		}
	}
	.-wrapper {
	}
}

.bg-cover {
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	&.-fixed {
		-webkit-background-attachment: fixed !important;
		-moz-background-attachment: fixed !important;
		-o-background-attachment: fixed !important;
		background-attachment: fixed !important;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	
}

//-------------------------------------------------------//

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	
}

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width
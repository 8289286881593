$main-color: $primary;

.nav-tabs {
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			border-radius: 0;
			border: none;
			color: $gray-700;
			border-left: 1px solid $gray-400;
			background-color: $gray-200;
			&.active {
				color: $white;
				border-color: $main-color;
				background-color: $main-color;
			}
		}
		&:first-child {
			.nav-link {
				border-left: none;
				border-top-left-radius: $border-radius;
			}
		}
		&:last-child {
			.nav-link {
				border-top-right-radius: $border-radius;
			}
		}
		&.align-right {
			margin-left: auto;
			.nav-link {
				border-left: none;
				border-top-left-radius: $border-radius;
			}
		}
	}
	border-bottom: 5px solid $main-color;
	.nav-link {
		cursor: pointer;
	}
}

label {
	color: $gray-500;
	font-weight: $font-weight-bold;
	font-size: 85%;
	text-transform: uppercase;
}

.form-group {
	&:last-child {
		margin-bottom: 0;
	}
}
.form-input {
	
}

.form-input-array {
	.form-group {
		margin-bottom: map-get($spacers, 2);
	}
}

.form-input-password {
	&.hide-pwd {
		-webkit-text-security: disc;
		-moz-text-security: disc;
		text-security: disc;
	}
}

.input-group {
	+ .invalid-feedback {
		display: block;
	}
	+ .valid-feedback {
		display: block;
	}
}
.custom-control {
	+ .invalid-feedback {
		display: block;
	}
	+ .valid-feedback {
		display: block;
	}
}
.select-control {
	&.is-valid {
		.form-input-select .dropdown-toggle {
			border-color: $success;
		}
	}
	&.is-invalid {
		.form-input-select .dropdown-toggle {
			border-color: $danger;	
		}
	}
	+ .invalid-feedback {
		display: block;
	}
	+ .valid-feedback {
		display: block;
	}
}

.form-input-switch {
	.btn {
		&.btn-light {
			border: $input-border-width solid $input-border-color;
		}
	}
}

.upload-control {
	.dropzone {
	/*	height: 60px;*/
		background-color: #EEE;
		border-width: 2px;
		border-color: rgb(102, 102, 102);
		border-style: dashed;
		border-radius: 4px;
		&.is-valid {
			border-color: $success;
		}
		&.is-invalid {
			border-color: $danger;
		}
		&:hover {
			background-color: #EEE;
		}
		.content-container {
			height: 100%;
			line-height: 60px;
			color: #999;
		}
	}
	+ .invalid-feedback {
		display: block;
	}
	+ .valid-feedback {
		display: block;
	}
}

.queued-file {
}
.loading-spin {
	&.bounce-loader {
		> div > div {
			border-radius: 100% !important;
		}
	}
	> div > div {
		vertical-align: middle;
	}
}

.loading-wrapper {
	position: relative;
	&.min-height {
		min-height: 40px;
	}
}
.loading-wrapper.active:after {
    
}

.loading-overlay-container {
	position: absolute;
	z-index: 2000;
	width: 100%;
	height: 100%;
	margin: auto;
	top:0; left:0; right: 0;
}
.loading-wrapper.fullscreen > .loading-overlay-container {
	position: fixed;
}

.loading-overlay {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #FFF;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.loading-overlay-container .loading-spin {
	position: absolute;
	z-index: 2000;
	top: 50%;
	left: 50%;
    vertical-align: middle;
}

.loading-kit-hideing-style {
	transition: all 0.1s;
	webkitTransition: all 0.1s;
}
.btn-group-vertical .reacto-dropdown .dropdown-menu .btn {
	width: auto;
}

a.no-style {
	color: inherit;
	text-decoration: none;
}

.reacto-dropdown {
	&.no-style {
		>.btn {
			padding: 0;
			border: none !important;
			border-radius: 0;
			&:focus {
				box-shadow: none !important;
			}
		}
		.dropdown-menu {
		}
	}
	>.btn:first-child {
		width: 100%;
		text-align: left;
		.caret {
			vertical-align: text-top;
			width: 16px;
			font-size: 150%;
			line-height: 100%;
			margin-left: map-get($spacers, 1);
		}
		&.btn-white {
			border: $input-border-width solid $input-border-color;
		}
	}
	.dropdown-toggle::after {
		display: none !important;
	}
	.btn:disabled {
		background-color: $input-disabled-bg;
		opacity: 1;
	}
	.dropdown-menu {
		&.scroll {
			max-height: 400px;
			overflow-y: scroll;
		}
		min-width: 100%;
		width: auto;
		.dropdown-item {
			cursor: pointer;
			&.active {
				.text-muted {
					color: $gray-300 !important;
				}
			}
		}
		> .dropdown-title-divider:first-child {
			border-top: none;
			margin-top: map-get($spacers, 2) * -1;
		}
		.dropdown-title-divider {
			background-color: $light;
			padding: map-get($spacers, 2);
			margin-top: map-get($spacers, 2);
			margin-bottom: map-get($spacers, 2);
			border-top: 1px solid $gray-300;
			border-bottom: 2px solid $primary;
		}
	}
}
.gallery {
	.gallery-item {
		transform: scaleZ(102%);
		cursor: pointer;
		position: relative;
		&:after {
			-webkit-transition: transform 0.3s, opacity 0.3s; /* Safari */
			transition: transform 0.3s, opacity 0.3s;
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0;
			background-color: $light;
		}
		&:hover {
			&:after {
				opacity: 0.5;
			}
	//		transform: scale(1.1);
		}
	}
}
.gallery-image-viewer {
	.gallery-ctrl {
		-webkit-transition: opacity 0.3s, background 0.3s; /* Safari */
		transition: opacity 0.3s, background 0.3s;
		font-size: 40px;
		color: $light;
		cursor: pointer;
		padding: 10px;
		opacity: 0.1;
		&:hover {
			opacity: 1;
		}
		&.gallery-ctrl-left {
			&:hover {
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.3+0,0+100;Neutral+Density */
				background: -moz-linear-gradient(left, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
			}
		}
		&.gallery-ctrl-right {
			text-align: right;
			&:hover {
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.3+100 */
				background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 */
			}
		}
	}
}
.modal-content {
	background-color: transparent;
//	.modal-header:first-child, .modal-body:first-child, .modal-footer:first-child {
//		@include border-top-radius($modal-content-border-radius);
//	}
//	:nth-last-child(2):not(.modal-header):not(.modal-body):not(.btn) {
//		@include border-bottom-radius($modal-content-border-radius);
//	}
//	.modal-header:last-child:not(.loading-overlay-container), .modal-body:last-child:not(.loading-overlay-container), .modal-footer:last-child:not(.loading-overlay-container) {
//		@include border-bottom-radius($modal-content-border-radius);
//	}
	.modal-body.no-footer {
		@include border-bottom-radius($modal-content-border-radius);
	}
	.modal-footer {
		@include border-bottom-radius($modal-content-border-radius);
	}
	
	.modal-header + .modal-footer {
		border-radius: 0;
	}
	>.loading-wrapper .loading-overlay-container {
		.loading-overlay {
			border-radius: $modal-content-border-radius;
		}
	}
}

.modal-header {
	background-color: $modal-content-bg;
	border-bottom: 3px solid $gray-300;
}

.modal-body {
	background-color: $modal-content-bg;
}

.modal-footer {
	.btn {
		min-width: 70px;
	}
	padding: .5rem;
	background-color: $gray-800;
}

/* Sidebar CSS */

.sidebar {
	min-width: 250px;
	.sidebar-title-nav-link {
		text-decoration: none;
	}
	.navbar {
		-webkit-transition: all 0.3s ease-in-out; /* Safari */
		transition: all 0.3s ease-in-out;
		padding: 0;
		//margin-top: 20px;
		&.vp-out {
		//	margin-top: 0px;
		}
		.sidebar-title {
		//	padding: 0.7rem 2.2rem;
			font-size: 110%;
			background-color: $gray-200;
			border-bottom: 2px solid $primary;
			.sidebar-title-label {
				color: $text-muted !important;
				font-weight: normal;
				font-size: .9rem;
			}
			.sidebar-title-text {
				min-height: 26px;
				color: $dark !important;
			}
		}
		&.navbar-dark {
			.nav-item {
				.nav-link {
					&.active {
						color: $light;
						background-color: $gray-900;
						border-left: 7px solid $primary;
					}
				}
			}
		}
		&.navbar-light {
			.nav-item {
				.nav-link {
					&.active {
						padding-left: 1.7rem;
						color: $primary;
						background-color: $gray-200;
						border-left: 0.5rem solid $primary;
					}
				}
			}
		}
	}
	.navbar-nav {
	//	padding: 0.7rem 0;
		max-width: 100%;
		.nav-item {
			min-width: 300px;
			width: 100%;
			.nav-link {
				padding: 0.7rem 2.2rem;
				font-weight: 600;
				-webkit-transition: all 0.3s ease-in-out; /* Safari */
				transition: all 0.3s ease-in-out;
				[class^="icon-"], [class*=" icon-"] {
					margin-right: 10px;
				}
			}
		}
	}
}


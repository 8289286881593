.dropzone {
	background-color: #EEE;
	border-width: 2px;
	border-color: rgb(102, 102, 102);
	border-style: dashed;
	border-radius: 4px;
}
.dropzone:hover {
	background-color: #EEE;
}
.dropzone .content-container {
	height: 100%;
	color: #999;
}
.queued-file {
	margin: 4px;
}
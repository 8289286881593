/* Icomoon */
@import './icomoon/style.css';

/* Bootstrap */
@import '../node_modules/bootstrap/scss/functions.scss';
@import './bootstrap-variables.scss';
@import '~bootstrap/scss/bootstrap';



/* ReactO Core */
@import './core/_L0_styles/_mixins';
@import './core/_L0_styles/Utilities/_typography';
@import './core/_L0_styles/Layout/_layout';
@import './core/_L0_styles/Widgets/_loading-kit';
@import './core/_L1_styles/Content/reacto-typography';
@import './core/_L1_styles/Layout/header';
@import './core/_L1_styles/Layout/sidebar';
@import './core/_L1_styles/Layout/section';
@import './core/_L1_styles/Elements/reacto-action-bar';
@import './core/_L1_styles/Elements/reacto-button';
@import './core/_L1_styles/Elements/reacto-dropdown';
@import './core/_L1_styles/Elements/reacto-modal';
@import './core/_L1_styles/Elements/reacto-form';
@import './core/_L1_styles/Elements/reacto-tabs';
@import './core/_L1_styles/Elements/reacto-table';
@import './core/_L1_styles/Widgets/gallery';
@import './core/_L1_styles/Widgets/gmaps';
@import './core/_L1_styles/Widgets/reacto-uploader';

/* Application */
@import './app/styles/app.scss';


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.at-sm {
		
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.contact-form-section {
		padding: 0 20px;
	}
	.at-md {

	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.at-lg {

	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.at-xl {

	}
}

html, body, #root {
	height: 100%;
	min-height: 100%;
	font-family: 'Titillium Web', sans-serif;
	font-weight: 300;
	/*letter-spacing: 2px;*/
	-webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}

b, strong {
	font-weight: 600 !important;
}

[class^="icon-"], [class*=" icon-"] {
//	line-height: inherit;
//	vertical-align: text-bottom;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 500;
}

.border-1 {
	border-width: 1px !important;
}
.border-2 {
	border-width: 2px !important;
}
.border-3 {
	border-width: 3px !important;
}
.border-4 {
	border-width: 4px !important;
}
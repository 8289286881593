.gmaps-info-window {
	color: $black;
	h5 {
		margin: 0;
		color: $primary;
	}
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	span {
		text-decoration: underline;
		line-height: 30px;
	}
}
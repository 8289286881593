@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?bw65nr");
  src: url("fonts/icomoon.eot?bw65nr#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?bw65nr") format("truetype"), url("fonts/icomoon.woff?bw65nr") format("woff"), url("fonts/icomoon.svg?bw65nr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-question:before {
  content: "\f128"; }

.icon-star:before {
  content: "\f005"; }

.icon-star-outline:before {
  content: "\f006"; }

.icon-user:before {
  content: "\f007"; }

.icon-road:before {
  content: "\f018"; }

.icon-picture:before {
  content: "\f03e"; }

.icon-water-drop:before {
  content: "\f043"; }

.icon-question-circle:before {
  content: "\f059"; }

.icon-logout:before {
  content: "\f08b"; }

.icon-wrench:before {
  content: "\f0ad"; }

.icon-cloud:before {
  content: "\f0c2"; }

.icon-money:before {
  content: "\f0d6"; }

.icon-lightbulb:before {
  content: "\f0eb"; }

.icon-cloud-download:before {
  content: "\f0ed"; }

.icon-cloud-upload:before {
  content: "\f0ee"; }

.icon-euro:before {
  content: "\f153"; }

.icon-file:before {
  content: "\f15c"; }

.icon-send:before {
  content: "\f1d8"; }

.icon-list-dot:before {
  content: "\e3a6"; }

.icon-note:before {
  content: "\e8cd"; }

.icon-caret-down:before {
  content: "\e90d"; }

.icon-caret-up-down:before {
  content: "\e90e"; }

.icon-caret-up:before {
  content: "\e90f"; }

.icon-switch-off:before {
  content: "\e906"; }

.icon-switch-on:before {
  content: "\e907"; }

.icon-plus:before {
  content: "\e145"; }

.icon-plus-circle:before {
  content: "\e147"; }

.icon-plus-circle-outline:before {
  content: "\e3ba"; }

.icon-archive:before {
  content: "\e149"; }

.icon-arrow-prev:before {
  content: "\e5c4"; }

.icon-arrow-next:before {
  content: "\e5c8"; }

.icon-close-circle:before {
  content: "\e5c9"; }

.icon-check1:before {
  content: "\e5ca"; }

.icon-navigate-prev:before {
  content: "\e408"; }

.icon-navigate-next:before {
  content: "\e409"; }

.icon-close-outline:before {
  content: "\e5cd"; }

.icon-pencil:before {
  content: "\e254"; }

.icon-dashboard:before {
  content: "\e871"; }

.icon-delete:before {
  content: "\e872"; }

.icon-minus-circle:before {
  content: "\e15c"; }

.icon-graph-bar:before {
  content: "\e01d"; }

.icon-upload:before {
  content: "\e2c6"; }

.icon-list:before {
  content: "\e241"; }

.icon-forward:before {
  content: "\e154"; }

.icon-lock:before {
  content: "\e897"; }

.icon-info:before {
  content: "\e88e"; }

.icon-info-outline:before {
  content: "\e88f"; }

.icon-caret-down-outline:before {
  content: "\e313"; }

.icon-caret-up-outline:before {
  content: "\e316"; }

.icon-refresh:before {
  content: "\e627"; }

.icon-pause:before {
  content: "\e034"; }

.icon-play:before {
  content: "\e037"; }

.icon-off:before {
  content: "\e8ac"; }

.icon-minus:before {
  content: "\e15b"; }

.icon-minus-circle-outline:before {
  content: "\e15d"; }

.icon-eye-on:before {
  content: "\e8f4"; }

.icon-replay:before {
  content: "\e042"; }

.icon-save:before {
  content: "\e161"; }

.icon-settings:before {
  content: "\e8b8"; }

.icon-graph-line:before {
  content: "\e6e1"; }

.icon-stop:before {
  content: "\e047"; }

.icon-list-3:before {
  content: "\e8ef"; }

.icon-eye-off:before {
  content: "\e8f5"; }

.icon-zoom-in:before {
  content: "\e8ff"; }

.icon-phone:before {
  content: "\e942"; }

.icon-envelop:before {
  content: "\e945"; }

.icon-location:before {
  content: "\e947"; }

.icon-clock-outline:before {
  content: "\e94e"; }

.icon-clock:before {
  content: "\e94f"; }

.icon-plus-big:before {
  content: "\ea0a"; }

.icon-pencil-2:before {
  content: "\e905"; }

.icon-equalizer:before {
  content: "\e992"; }

.icon-list-2:before {
  content: "\e9bb"; }

.icon-download:before {
  content: "\e9c7"; }

.icon-arrow-up:before {
  content: "\ea3a"; }

.icon-arrow-right:before {
  content: "\ea3c"; }

.icon-arrow-down:before {
  content: "\ea3e"; }

.icon-arrow-back:before {
  content: "\ea40"; }

.icon-circle-up:before {
  content: "\ea41"; }

.icon-circle-right:before {
  content: "\ea42"; }

.icon-circle-down:before {
  content: "\ea43"; }

.icon-circle-left:before {
  content: "\ea44"; }

.icon-sort-asc:before {
  content: "\ea48"; }

.icon-sort-desc:before {
  content: "\ea49"; }

.icon-new-tab:before {
  content: "\ea7e"; }

.icon-calendar:before {
  content: "\e910"; }

.icon-notice:before {
  content: "\e911"; }

.icon-notice-outline:before {
  content: "\e912"; }

.icon-warning:before {
  content: "\e913"; }

.icon-check:before {
  content: "\e900"; }

.icon-cancel:before {
  content: "\e901"; }

.icon-map:before {
  content: "\e914"; }

.icon-layout-grid:before {
  content: "\e902"; }

.icon-layout-page:before {
  content: "\e903"; }

.icon-layout-page-admin:before {
  content: "\e904"; }

/* Header CSS */

$navbar_height: 80px;

.header {
	min-height: $navbar_height;
	.brand {
		-webkit-transition: all 0.2s ease-in-out; /* Safari */
		transition: all 0.2s ease-in-out;
		padding: 0 1rem;
		.navbar-brand {
			padding: 0;
		}
	}
	.navbar {
		//background-color: $primary !important;
		-webkit-transition: all 0.3s ease-in-out; /* Safari */
		transition: all 0.3s ease-in-out;
		padding: 0;
		padding: calc((#{$navbar_height} - 40px) / 2) 1rem;
		//margin-top: 20px;
		&.vp-out {
		//	margin-top: 0px;
		}
	}
	.navbar-nav {
		.nav-link {
			font-weight: 600;
			text-transform: uppercase;
			[class^="icon-"], [class*=" icon-"] {
				margin-right: 10px;
				line-height: inherit;
				vertical-align: text-top;
			}
		}
		.account-nav-item {
			.nav-link {
				text-transform: inherit;
			}
		}
	}
}

.navbar-toggler {
	z-index: 1103;
	//background-color: $primary !important;
	-webkit-transition: background-color 0.3s ease-in-out; /* Safari */
	transition: background-color 0.3s ease-in-out;
	&:focus {
		outline: none;
	}
	&.opened {
		background-color: var(--gray);	
	}
}


.navbar-collapse {
//	background-color: var(--gray-dark);
}

///////////////////////////////////////
//
// .navbar-collapse when EXPANDED
//
///////////////////////////////////

@mixin navbar-collapse-expanded {
	-webkit-transition: left 0.3s, right 0.3s; /* Safari */
	transition: left 0.3s, right 0.3s;
	box-shadow: none;
	&.centered-collapse {
		visibility: visible !important;
		opacity: 1 !important;
	}
	.navbar-nav {
		&.nav-left {
			margin-left: 0;
			margin-right: auto;
		}
		&.nav-center {
			margin: 0 auto;
		}
		&.nav-right {
			margin-left: auto;
			margin-right: 0;
		}
	}
	.nav-item {
		&.is-current {
			.nav-link {
				color: $primary;
			}
		}
	}
	.nav-link {
		padding-left: $nav-link-padding-x;
		padding-right: $nav-link-padding-x;
		&.active {
			color: $primary;
		}
	}
	+ .backdrop {
		visibility: hidden;
		opacity: 0;
	}
}

@mixin _navbar-collapse-expanded {
	padding-top: 0 !important;
	width: inherit !important;
	max-width: inherit !important;
}

@mixin navbar-brand-expanded {
	
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.navbar-expand-sm .navbar-brand {
		@include navbar-brand-expanded;
	}
	.navbar-expand-sm .navbar-collapse {
		@include _navbar-collapse-expanded;
		@include navbar-collapse-expanded;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.navbar-expand-md .navbar-brand {
		@include navbar-brand-expanded;
	}
	.navbar-expand-md .navbar-collapse {
		@include _navbar-collapse-expanded;
		@include navbar-collapse-expanded;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.navbar-expand-lg .navbar-brand {
		@include navbar-brand-expanded;
	}
	.navbar-expand-lg .navbar-collapse {
		@include _navbar-collapse-expanded;
		@include navbar-collapse-expanded;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.navbar-expand-xl .navbar-brand {
		@include navbar-brand-expanded;
	}
	.navbar-expand-xl .navbar-collapse {
		@include _navbar-collapse-expanded;
		@include navbar-collapse-expanded;
	}
}

/////////////////////////////////////


///////////////////////////////////////
//
// .navbar-collapse when COLLAPSED
//
///////////////////////////////////

@mixin navbar-collapse-collapsed {
	-webkit-transition: left 0.3s, right 0.3s, box-shadow 0.3s, visibility 0.3s, opacity 0.3s; /* Safari */
	transition: left 0.3s, right 0.3s, box-shadow 0.3s, visibility 0.3s, opacity 0.3s;
	@include boxShadow(0, 0, 22px, #000, 0, 1px);
	&.opened {
		@include boxShadow(0, 0, 22px, #000, 0.75, 1px);
	}
	.navbar-nav {
		margin: 0 auto;
		width: 100%;
	}
	.nav-item {
		color: yellow !important;
		&:last-child:after { display: none; }
		&:after {
			display: block;
			content: "";
			margin: 0 20px;
			height: 1px;
			background-color: rgba($black, .5);
		}
		&.is-current {
			.nav-link {
				color: $primary;
			}
		}
	}
	.nav-link {
		padding-left: $nav-link-padding-x;
		padding-right: $nav-link-padding-x;
		margin: 5px 15px;
		&.active {
			color: $primary;
		}
	}
	+ .backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $black;
		z-index: 1101;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: visibility 0.3s, opacity 0.3s linear; /* Safari */
		transition: visibility 0.3s, opacity 0.3s linear;
		&.opened {
			visibility: visible;
			opacity: 0.5;
		}
	}
	z-index: 1102;
}

@mixin _navbar-collapse-collapsed {
	position: fixed;
	display: block;
	top: 0;
	bottom: 0;
	overflow-y: scroll;
	margin-right: 0 !important;
	text-align: center;
	background-color: inherit;
}

@mixin navbar-brand-collapsed {
	img {
		max-width: 150px;
	}
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.navbar-expand-sm .navbar-brand {
		@include navbar-brand-collapsed;
	}
	.navbar-expand-sm .navbar-collapse {
		@include _navbar-collapse-collapsed;
		@include navbar-collapse-collapsed;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.navbar-expand-md .navbar-brand {
		@include navbar-brand-collapsed;
	}
	.navbar-expand-md .navbar-collapse {
		@include _navbar-collapse-collapsed;
		@include navbar-collapse-collapsed;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.navbar-expand-lg .navbar-brand {
		@include navbar-brand-collapsed;
	}
	.navbar-expand-lg .navbar-collapse {
		@include _navbar-collapse-collapsed;
		@include navbar-collapse-collapsed;
	}
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	.navbar-expand-xl .navbar-brand {
		@include navbar-brand-collapsed;
	}
	.navbar-expand-xl .navbar-collapse {
		@include _navbar-collapse-collapsed;
		@include navbar-collapse-collapsed;
	}
}

/////////////////////////////////////